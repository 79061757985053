import loader from "../assets/loader.gif";

import "../App.css";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import { TABLE_COLUMNS } from "../constants/tableColumns";
import Button from "./Button";
import PaymentNodeSelect from "./PaymentNodeSelect";
import ButtonSecondary from "./ButtonSecondary";

function StatusSection({
  availableBalance,
  totalBalance,
  homeDataError,
  homeLoading,
  jsonObjectArray,
  generateTaxCSV,
  csvLoading,
  csvError,
  paymentNodes,
  activeNode,
  setActiveNode,
  sellAssetLoading,
  sellAsset,
  setShowVerifyIdentityModal,
  sellAssetError,
  handleOpenConnectModal,
  handleOpenVerifyModal,
  isBankReadyForWithdrawal,
  isBankPending,
  bankReadyForWithdrawalMask,
  bankPendingMask,
  showVerifyIdentityButton,
}) {
  const [showTaxButtons, setShowTaxButtons] = useState(false);
  const readyNodes = paymentNodes?.filter(node => node.readyForWithdrawal && node.partner === "CHECKBOOK");

  const handleGenerateCsv = (year) => {
    setShowTaxButtons(false);
    generateTaxCSV(year);
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formattedAmount = (amount) => {
    if (!amount) {
      return formatter.format(0);
    }

    // Ensure the amount is a string with at least 3 characters
    let amountStr = amount.toString();
    if (amountStr.length < 3) {
      amountStr = amountStr.padStart(3, '0');
    }

    const partOne = amountStr.slice(0, -2);
    const partTwo = amountStr.slice(-2);
    const combined = partOne + "." + partTwo;

    return formatter.format(parseFloat(combined));
  };
  
  const formattedDate = (date) => {
    return moment(date).format("MM/DD/YY - HH:mm");
  };

  return (
    <>
      <div style={{ marginTop: 40 }}>
        <h3 style={{ fontSize: 24, fontWeight: 600, marginBottom: 10 }}>
          Balance
        </h3>
        <div sx={{ maxWidth: 1024 }}>
          <b>Total balance: </b>
          {formattedAmount(totalBalance)}
          <br />
          <b>Currently available for withdrawal: </b>
          {formattedAmount(availableBalance)}
          {isBankReadyForWithdrawal &&
            <div>
              {readyNodes[0]?.instition?.name !== '' && <PaymentNodeSelect
                  onConnectBank={handleOpenConnectModal}
                  paymentNodes={readyNodes}
                  activeNode={readyNodes[0]}
                  setActiveNode={setActiveNode}
                  onVerifyNode={handleOpenVerifyModal}
              />}
              {readyNodes[0]?.instition?.name === '' &&
                  <div>
                    <b>Successfully connected bank account: </b> {bankReadyForWithdrawalMask}
                  </div>
              }
            </div>
          }
          {isBankPending &&
              <div>
                <b>Bank awaiting micro-deposit verification: </b>{bankPendingMask}
              </div>
          }
          {!isBankReadyForWithdrawal && !isBankPending &&
            <Button isLoading={sellAssetLoading} onClick={handleOpenConnectModal}>
              Confirm Bank Account
            </Button>
          }
          {!isBankReadyForWithdrawal && isBankPending &&
            <Button isLoading={sellAssetLoading} onClick={handleOpenVerifyModal}>
              Enter Micro Deposits
            </Button>
          }
          {isBankReadyForWithdrawal && availableBalance > 0 && <Button isLoading={sellAssetLoading} onClick={sellAsset}>
            Initiate Withdrawal
          </Button>}
          {showVerifyIdentityButton && (<Button onClick={setShowVerifyIdentityModal}>
            Verify Identity
          </Button>)}

          {!sellAssetLoading && sellAssetError !== "" && (
            <div style={{ paddingTop: 10, color: "red" }}>{sellAssetError}</div>
          )}
        </div>
        <br />
        <h3 style={{ fontSize: 24, fontWeight: 600, marginBottom: 10 }}>
          Documents
        </h3>
        <Button
          isLoading={csvLoading}
          onClick={() => setShowTaxButtons(!showTaxButtons)}
        >
          Download Tax Statements
        </Button>
        {showTaxButtons && (
          <div style={styles.taxButtons}>
            <ButtonSecondary onClick={() => handleGenerateCsv("FY2022")}>
              FY2022
            </ButtonSecondary>
            <ButtonSecondary onClick={() => handleGenerateCsv("FY2021")}>
              FY2021
            </ButtonSecondary>
          </div>
        )}
        {!csvLoading && csvError !== "" && (
          <div style={{ paddingTop: 10, color: "red" }}>{csvError}</div>
        )}

        <br />
        <h3 style={{ fontSize: 24, fontWeight: 600, marginBottom: 20 }}>
          Legend
        </h3>
        <Card sx={{ maxWidth: 1024, backgroundColor: "#FAF9F7" }}>
          <CardContent
            style={{
              paddingBottom: 10,
              paddingTop: 15,
              paddingRight: 10,
              display: "flex",
              justifyContent: "space-between",
              overflowY: "auto",
              overflowX: "auto",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{
                minWidth: 150,
                fontSize: 14,
                whiteSpace: "nowrap",
                marginRight: 2,
              }}
              color="text.secondary"
              gutterBottom
            >
              <b>QUEUED</b>
              <br />
              <span style={{ whiteSpace: "normal" }}>
                Your transaction is waiting in a queue to be processed by our
                banking partner.
              </span>
            </Typography>
            <Typography
              sx={{
                minWidth: 150,
                fontSize: 14,
                whiteSpace: "nowrap",
                marginRight: 2,
              }}
              color="text.secondary"
              gutterBottom
            >
              <b>PROCESSING</b>
              <br />
              <span style={{ whiteSpace: "normal" }}>
                Our banking partner has begun processing your transaction.
              </span>
            </Typography>
            <Typography
              sx={{
                minWidth: 150,
                fontSize: 14,
                whiteSpace: "nowrap",
                marginRight: 2,
              }}
              color="text.secondary"
              gutterBottom
            >
              <b>ON THE WAY</b>
              <br />
              <span style={{ whiteSpace: "normal" }}>
                Your funds have been transferred by our banking partner and are
                on the way to your bank account.
              </span>{" "}
            </Typography>
            <Typography
              sx={{
                minWidth: 150,
                fontSize: 14,
                whiteSpace: "nowrap",
                marginRight: 2,
              }}
              color="text.secondary"
              gutterBottom
            >
              <b>SETTLED</b>
              <br />
              <span style={{ whiteSpace: "normal" }}>
                The funds have been received in your bank account.
              </span>{" "}
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div style={{ width: "100%", alignItems: "center", marginTop: 40 }}>
        <h3 style={{ fontSize: 24, fontWeight: 600, marginBottom: 20 }}>
          Transactions
        </h3>
        {!homeLoading &&
          homeDataError === "" &&
          jsonObjectArray &&
          jsonObjectArray.length && (
            <TableContainer className="table" component={Paper}>
              <Table stickyHeader>
                <TableHead className="header">
                  <TableRow>
                    {TABLE_COLUMNS.map((columnName, index) => (
                      <TableCell className="headerCells" key={index}>
                        {columnName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.prototype.slice.call(jsonObjectArray).map((row) => (
                    <TableRow key={row.updatedDate}>
                      <TableCell className="bodyCells">
                        {formattedDate(row.updatedDate)}
                      </TableCell>
                      <TableCell className="bodyCells">
                        {formattedAmount(row.amount.amount)}
                      </TableCell>
                      <TableCell className="bodyCells">{row.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        {homeLoading && (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <img alt="loading" src={loader} style={styles.loader} />
          </div>
        )}
        {!homeLoading && homeDataError !== "" && (
          <div style={{ textAlign: "center", color: "red" }}>
            {homeDataError}
          </div>
        )}
      </div>
    </>
  );
}

const styles = {
  loader: {
    height: 20,
    width: 20,
  },
  taxButtons: {
    display: "flex",
    flexDirection: "row",
    marginRight: 21,
  },
};

export default StatusSection;
