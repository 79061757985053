import loader from "../assets/loader.gif";

import useWindowDimensions from "../hooks/useWindowDimensions";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { PinInput } from "react-input-pin-code";

function VerificationCodeStep({
  verificationPin,
  setVerificationPin,
  secondsRemaining,
  resendCode,
  submitOTPError,
  decreaseStep,
  validVerificationPin,
  submitOTP,
  loading,
}) {
  const { width } = useWindowDimensions();

  return (
    <>
      <div style={{ marginTop: 40, color: "#62605D", textAlign: "center" }}>
        Enter the verification code sent to your phone
      </div>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          marginTop: 30,
          height: 50,
        }}
      >
        <PinInput
          size={width > 300 ? "md" : "sm"}
          placeholder="•"
          type="number"
          borderColor="#DCD9D5"
          focusBorderColor="#AEABA6"
          validBorderColor="#FFC693"
          containerStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          values={verificationPin}
          onChange={(value, index, values) => setVerificationPin(values)}
        />
        <div
          onClick={() => secondsRemaining === 0 && resendCode()}
          style={
            secondsRemaining > 0
              ? styles.resendButtonCooldown
              : styles.resendButton
          }
        >
          {secondsRemaining > 0 ? (
            <div>{secondsRemaining}</div>
          ) : (
            <div style={{ cursor: "pointer" }}>Resend code</div>
          )}
        </div>
        {submitOTPError !== "" && (
          <div style={{ color: "red", textAlign: "center" }}>
            {submitOTPError}
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div onClick={() => decreaseStep()} style={styles.backButton}>
            <KeyboardArrowLeftIcon />
          </div>
          <div
            onClick={() => validVerificationPin && submitOTP()}
            style={
              validVerificationPin
                ? styles.submitButton
                : styles.submitButtonDisabled
            }
          >
            {!loading && <>Continue</>}
            {loading && (
              <img alt="loading" src={loader} style={styles.loader} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  submitButton: {
    cursor: "pointer",
    marginTop: 60,
    bottom: 20,
    color: "white",
    backgroundColor: "#ff7c7c",
    borderRadius: 5,
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backButton: {
    cursor: "pointer",
    marginTop: 60,
    bottom: 20,
    width: 60,
    marginRight: 10,
    color: "white",
    backgroundColor: "#ff7c7c",
    borderRadius: 5,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submitButtonDisabled: {
    marginTop: 60,
    color: "white",
    backgroundColor: "#ffbdbd",
    borderRadius: 5,
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  resendButton: {
    pointer: "cursor",
    marginTop: 10,
    color: "#312F2E",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  resendButtonCooldown: {
    marginTop: 10,
    color: "#AEABA6",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    height: 20,
    width: 20,
  },
};

export default VerificationCodeStep;
