import React from "react";
import { isValidNumber } from "libphonenumber-js";
import useWindowDimensions from "../hooks/useWindowDimensions";
import useCountdown from "../hooks/useCountdown";
import * as pairing from "../utils/pairing";
import * as api from "../api";

import PhoneNumberStep from "../components/PhoneNumberStep";
import VerificationCodeStep from "../components/VerificationCodeStep";
import PinCodeStep from "../components/PinCodeStep";

const API_URL = process.env.REACT_APP_BASE_URL;
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

function SignInScreen(props) {
  const { pairingData, setPairingData, logOut, setAuthToken } = props;

  const { width } = useWindowDimensions();

  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [initialLoadingComplete, setInitialLoadingComplete] =
    React.useState(false);

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("US");

  const [verificationPin, setVerificationPin] = React.useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [pinCode, setPinCode] = React.useState(["", "", "", ""]);

  const [requestOTPError, setRequestOTPError] = React.useState("");
  const [submitOTPError, setSubmitOTPError] = React.useState("");
  const [loginError, setLoginError] = React.useState("");

  const validNumber = isValidNumber(phoneNumber || "", countryCode);
  const validVerificationPin =
    verificationPin[0] !== "" &&
    verificationPin[1] !== "" &&
    verificationPin[2] !== "" &&
    verificationPin[3] !== "" &&
    verificationPin[4] !== "" &&
    verificationPin[5] !== "";

  const validPinCode =
    pinCode[0] !== "" &&
    pinCode[1] !== "" &&
    pinCode[2] !== "" &&
    pinCode[3] !== "";

  React.useEffect(() => {
    const localStorageUserId = localStorage.getItem("userId");
    const localStoragePrivateKey = localStorage.getItem("privateKey");
    if (localStorageUserId && localStoragePrivateKey) {
      setPairingData({
        userId: localStorageUserId,
        privateKey: localStoragePrivateKey,
      });
      setStep(2);
    }
    setInitialLoadingComplete(true);
  }, [setPairingData]);

  const increaseStep = () => {
    setStep(step + 1);
  };

  const decreaseStep = () => {
    setStep(step - 1);
  };

  const requestOTP = async (scope) => {
    setLoading(true);
    try {
      const encodedPhoneNumber = encodeURIComponent(phoneNumber);
      const encodedScope = "credentials%3A" + scope;
      const response = await fetch(
        `${API_URL}/otp/request?phone_number=${encodedPhoneNumber}&scope=${encodedScope}`
      );

      const responseBody = await response.json();

      if (response.status === 200) {
        startCooldown();
        increaseStep();
        setLoading(false);
        setRequestOTPError("");
      } else {
        switch (responseBody.code) {
          case "UNKNOWN_PHONE_NUMBER":
            setRequestOTPError("Unknown phone number");
            setLoading(false);
            return;
          default:
            setRequestOTPError("An unknown error occurred");
            setLoading(false);
            return;
        }
      }
    } catch (error) {
      setRequestOTPError("An unknown error occurred");
      setLoading(false);
    }
  };

  const { secondsRemaining, start: startCooldown } = useCountdown(30);

  const resendCode = async () => {
    startCooldown();
    try {
      const encodedPhoneNumber = encodeURIComponent(phoneNumber);
      const encodedScope = "credentials%3Apair";
      const response = await fetch(
        `${API_URL}/otp/request?phone_number=${encodedPhoneNumber}&scope=${encodedScope}`
      );

      if (response.status !== 200) {
        setSubmitOTPError("An unknown error occurred");
      }
    } catch (error) {
      setSubmitOTPError("An unknown error occurred");
    }
  };

  const pairDevice = async (token) => {
    try {
      const { privateKey, publicKey } = pairing.generateDeviceKeyPair();

      const body = { devicePublicKey: publicKey, webAuth: true };
      const headers = { Authorization: `token ${token}` };

      const response = await api.post("/auth/pair", body, headers);
      const responseBody = await response.json();
      if (response.status !== 201) {
        throw new Error(responseBody.code);
      }
      return { userId: responseBody.userId, privateKey };
    } catch (error) {
      console.log(error);
    }
  };

  const signIn = async (data) => {
    const {
      pin,
      pairingData: { userId, privateKey },
    } = data;

    try {
      const nonce = pairing.generateNonce();
      const webAuth = true;

      const body = { pin, nonce, userId, webAuth };

      const headers = pairing.generateSignatureHeaders(
        `${nonce}.${pin}.${userId}`,
        privateKey
      );

      const response = await api.post("/auth/login", body, headers);
      const responseBody = await response.json();

      if (response.status !== 200) {
        switch (responseBody.code) {
          case "INVALID_PIN":
            setLoginError("Incorrect PIN");
            return;
          default:
            setLoginError("");
            logOut();
            return;
        }
      }
      increaseStep();
      return {
        authToken: responseBody.authToken,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const resetPin = async (token, pin) => {
    try {
      const body = { pin };
      const headers = { Authorization: `token ${token}` };

      const response = await api.post("/auth/reset", body, headers);
      const responseBody = await response.json();
      if (response.status !== 201) {
        throw new Error(responseBody.code);
      }

      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const redeemToken = async () => {
    const code =
      verificationPin[0] +
      verificationPin[1] +
      verificationPin[2] +
      verificationPin[3] +
      verificationPin[4] +
      verificationPin[5];

    const response = await api.post("/otp/redeem", {
      phoneNumber: phoneNumber,
      code,
    });
    const responseBody = await response.json();

    if (response.status !== 200) {
      switch (responseBody.code) {
        case "OTP_CODE_ALREADY_USED":
          setSubmitOTPError("Code has already been used");
          setLoading(false);
          return;
        case "OTP_CODE_INVALID":
          setSubmitOTPError("Incorrect verification code");
          setLoading(false);
          return;
        default:
          setSubmitOTPError("An unknown error occurred");
          setLoading(false);
          return;
      }
    }

    return responseBody.token;
  };

  const submitOTP = async () => {
    setLoading(true);
    try {
      const token = await redeemToken();
      setLoading(false);
      const pairingData = await pairDevice(token);
      if (pairingData) {
        setPairingData(pairingData);
        increaseStep();
      } else {
        setSubmitOTPError("An unknown error occurred");
      }
    } catch (error) {
      console.log(error);
      setSubmitOTPError("An unknown error occurred");
      setLoading(false);
    }
  };

  const forgotPin = () => {
    setVerificationPin(["", "", "", "", "", ""]);
    setPinCode(["", "", "", ""]);
    increaseStep();
  };

  const submitResetPin = async () => {
    setLoading(true);
    const token = await redeemToken();

    const aT = await resetPin(token, pinCode.join(""));
    await submitPin();
    setLoading(false);
  };

  const submitPin = async () => {
    setLoading(true);
    const iT = await api.getIntercomHashAndInit({
      pin: pinCode.join(""),
      pairingData,
    });
    const aT = await signIn({ pin: pinCode.join(""), pairingData });
    setLoading(false);

    if (aT) {
      setAuthToken(aT.authToken);
      localStorage.setItem("userId", pairingData.userId);
      localStorage.setItem("privateKey", pairingData.privateKey);
    }

    if (iT) {
      window.Intercom("boot", {
        app_id: INTERCOM_APP_ID,
        user_id: pairingData.userId,
        user_hash: iT.intercomHash,
      });
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <PhoneNumberStep
            setPhoneNumber={setPhoneNumber}
            setCountryCode={setCountryCode}
            requestOTPError={requestOTPError}
            validNumber={validNumber}
            description={"Enter your phone number to continue"}
            requestOTP={() => requestOTP("pair")}
            loading={loading}
          />
        );
      case 1:
        return (
          <VerificationCodeStep
            verificationPin={verificationPin}
            setVerificationPin={setVerificationPin}
            secondsRemaining={secondsRemaining}
            resendCode={resendCode}
            submitOTPError={submitOTPError}
            decreaseStep={decreaseStep}
            validVerificationPin={validVerificationPin}
            submitOTP={submitOTP}
            loading={loading}
          />
        );
      case 2:
        return (
          <PinCodeStep
            pinCode={pinCode}
            setPinCode={setPinCode}
            loginError={loginError}
            paired={pairingData}
            decreaseStep={decreaseStep}
            description={"Enter your account PIN code"}
            validPinCode={validPinCode}
            submitPin={submitPin}
            loading={loading}
            forgotPin={forgotPin}
          />
        );
      case 3:
        return (
          <PhoneNumberStep
            setPhoneNumber={setPhoneNumber}
            setCountryCode={setCountryCode}
            requestOTPError={requestOTPError}
            validNumber={validNumber}
            description={
              "We'll send you a code via SMS to begin resetting your PIN"
            }
            requestOTP={() => requestOTP("reset")}
            loading={loading}
          />
        );
      case 4:
        return (
          <VerificationCodeStep
            verificationPin={verificationPin}
            setVerificationPin={setVerificationPin}
            secondsRemaining={secondsRemaining}
            resendCode={resendCode}
            submitOTPError={submitOTPError}
            decreaseStep={decreaseStep}
            validVerificationPin={validVerificationPin}
            submitOTP={increaseStep}
            loading={loading}
          />
        );
      case 5:
        return (
          <PinCodeStep
            pinCode={pinCode}
            setPinCode={setPinCode}
            loginError={loginError}
            paired={pairingData}
            decreaseStep={decreaseStep}
            validPinCode={validPinCode}
            submitPin={submitResetPin}
            description={"Create a fresh 4-digit PIN to access your account"}
            loading={loading}
          />
        );
      default:
        return (
          <PhoneNumberStep
            setPhoneNumber={(number) => {
              setPhoneNumber(number);
            }}
            setCountryCode={(Code) => {
              setCountryCode(Code);
            }}
            description={"Enter your phone number to continue"}
            requestOTPError={requestOTPError}
            validNumber={validNumber}
            requestOTP={() => requestOTP("pair")}
            loading={loading}
          />
        );
    }
  };

  return (
    <>
      {initialLoadingComplete && (
        <>
          <div style={styles.container}>
            <div style={styles.instructionsContainer}>
              <h1 style={width > 350 ? styles.semiBold : styles.semiBoldSmall}>
                Log in to Donut
              </h1>
              {renderStep()}
            </div>
          </div>
        </>
      )}
    </>
  );
}

const styles = {
  container: {
    maxWidth: 1000,
    width: "100%",
    margin: 0,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    alignItems: "center",
  },
  instructionsContainer: {
    alignItems: "center",
    marginTop: 0,
    marginBottom: 20,
    maxWidth: 400,
    width: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  withdrawalsContainer: {
    alignItems: "center",
    marginTop: 0,
    marginBottom: 20,
    maxWidth: 800,
    width: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  logo: {
    width: "auto",
    height: 40,
    marginTop: 20,
    marginLeft: 20,
    flex: 1,
  },
  semiBold: {
    fontSize: 30,
    marginBottom: -10,
    fontWeight: 700,
    textAlign: "center",
  },
  semiBoldSmall: {
    fontSize: 24,
    fontWeight: 700,
    textAlign: "center",
  },
};

export default SignInScreen;
