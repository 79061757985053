import React, { useEffect, useRef, useState } from "react";
import { GraphQLClient } from "graphql-request";
import { Veriff } from '@veriff/js-sdk';
import StatusSection from "../components/StatusSection";
import useWindowDimensions from "../hooks/useWindowDimensions";
import * as csv from "../utils/csv";
import { HOME_QUERY, TRANSACTIONS_QUERY } from "../constants/gqlQueries";
import {
  buildAddManualBankAccountReq,
  buildTaxCSVReq,
  buildSellAssetReq,
  GENERATE_TAX_CSV,
  SELL_ASSETS_MUTATION,
  ADD_MANUAL_BANK_ACCOUNT,
  VERIFY_MICRO_DEPOSITS,
  buildVerifyMicroDepositsReq,
} from "../constants/gqlMutations";
import ReactModal from "react-modal";
import ButtonClose from "../components/ButtonClose";
import Button from "../components/Button";
import SwitchButton from "../components/SwitchButton";
import PaymentNodeSelect from "../components/PaymentNodeSelect";
import BankConnectModal from "../components/BankConnectModal";
import ConnectBankModal from "../components/BankConnectModal";

const GRAPHQL_API_URL = process.env.REACT_APP_GRAPHQL_BASE_URL;

function HomeScreen(props) {
  const { authToken } = props;
  const { width } = useWindowDimensions();
  const [homeLoading, setHomeLoading] = useState(true);
  const [homeDataError, setHomeDataError] = useState("");
  const [jsonObjectArray, setJsonObjectArray] = useState();
  const [totalBalance, setTotalBalance] = useState();
  const [availableBalance, setAvailableBalance] = useState();
  const [csvLoading, setCsvLoading] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showVerifyIdentityModal, setShowVerifyIdentityModal] = useState(false);
  const [confirmedWithdrawal, setConfirmedWithdrawal] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showSellAssetModal, setShowSellAssetModal] = useState(false); // New state for Sell Asset modal
  const [csvError, setCsvError] = useState("");
  const [paymentNodes, setPaymentNodes] = useState(null);
  const [activeNode, setActiveNode] = useState(null);
  const [isSellSent, setIsSellSent] = useState(false);
  const [sellAssetLoading, setSellAssetLoading] = useState(false);
  const [isBankReadyForWithdrawal, setIsBankReadyForWithdrawal] = useState(false);
  const [bankMaskReadyForWithdrawal, setBankMaskReadyForWithdrawal] = useState('');
  const [isBankPending, setIsBankPending] = useState(false);
  const [bankPendingPaymentNodeId, setBankPendingPaymentNodeId] = useState('');
  const [bankMaskPending, setBankMaskPending] = useState('');
  const [kycStatus, setKycStatus] = useState('');
  const [sellAssetError, setSellAssetError] = useState("");
  const [userId, setUserId] = useState("");
  const pollRef = useRef();
  const [connectBankForm, setConnectBankForm] = useState({
    accountNumber: "",
    routingNumber: "",
    lastFourDigitsSSN: "",
    confirmationDate: "",
    accountType: "CHECKING",
  });
  const [connectBankLoading, setConnectBankLoading] = useState(false);
  const [connectBankError, setConnectBankError] = useState("");
  const [connectBankMessage, setConnectBankMessage] = useState("");
  const [microdepositForm, setMicrodepositForm] = useState({
    amount1: "",
    amount2: "",
  });
  const [microdepositLoading, setMicrodepositLoading] = useState(false);
  const [microdepositError, setMicrodepositError] = useState("");
  const [microdepositNode, setMicrodepositNode] = useState(null);


  const handleUpdateConnectBankForm = (key, value) => {
    let newForm = { ...connectBankForm, [key]: value };
    setConnectBankForm(newForm);
  };

  const handleUpdateMicrodepositsForm = (key, value) => {
    if (value.length > 2) return;
    let newForm = { ...microdepositForm, [key]: value };
    setMicrodepositForm(newForm);
  };

  const handleOpenVerifyModal = async (node) => {
    setMicrodepositNode(node);
    setShowVerifyModal(true);
  };

  const handleCloseVerifyModal = async () => {
    setMicrodepositNode(null);
    setShowVerifyModal(false);
    getHomeQuery();
    setTimeout(() => {
      getHomeQuery();
    }, 5000);
  };

  const handleOpenConnectModal = async () => {
    setShowConnectModal(true);
  };

  const handleCloseConnectModal = async () => {
    setShowConnectModal(false);
    getHomeQuery();
    setTimeout(() => {
      getHomeQuery();
    }, 5000);
  };

  const handleConnectSubmit = async () => {
    setConnectBankLoading(true);
    const { accountNumber, routingNumber, accountType, confirmationDate, lastFourDigitsSSN } = connectBankForm;

    try {
      const graphQLClient = new GraphQLClient(GRAPHQL_API_URL + "/graphql", {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      const data = await graphQLClient.request(ADD_MANUAL_BANK_ACCOUNT, {
        input: buildAddManualBankAccountReq(
            accountType,
            accountNumber,
            routingNumber,
            lastFourDigitsSSN,
        ),
      });

      if(!data.addManualBankAccount.succeeded) {
        setConnectBankError(
            "Oops, something went wrong. Please ensure all details are correct and try again. If the issue persists, contact our support team for assistance."
        );
      } else {
        const homeData = await graphQLClient.request(HOME_QUERY);
        const bankReadyForWithdrawal = homeData.paymentNodes.find(node => node.readyForWithdrawal && node.partner === 'CHECKBOOK');
        const bankPending = homeData.paymentNodes.find(node => node.manualVerificationRequired && !node.readyForWithdrawal && node.partner === 'CHECKBOOK');
        if (bankReadyForWithdrawal && bankReadyForWithdrawal.validSSN) {
          setConnectBankMessage(`You have successfully prepared your account for withdrawal, and no further action is required from you at this time.
        <br/><br/>We expect funds to be available for withdrawal soon and will contact you when they are ready.`);
        } else if (bankReadyForWithdrawal && !bankReadyForWithdrawal.validSSN) {
          setConnectBankMessage(`Thank you for preparing your account for withdrawal. No further action is required from you at this time.
        <br/><br/>We expect funds to be available for withdrawal soon and will contact you when they are ready.`);
        } else if (bankPending) {
          setConnectBankMessage(`To complete the connection of your new bank account, please verify it via micro-deposits.
        <br/><br/>Please allow 3 - 5 business days for micro-deposits to arrive.
        <br/><br/>Your account may undergo further security checks resulting in extended distribution timelines.
        <br/><br/>We expect funds to be available for withdrawal soon and will contact you when they are ready.`);
        }
      }
    } catch (error) {
      setConnectBankError(
          "Oops, something went wrong. Please ensure all details are correct and try again. If the issue persists, contact our support team for assistance."
      );
    } finally {
      setConnectBankLoading(false);
    }
  };

  const handleMicrodepositsSubmit = async () => {
    if (bankPendingPaymentNodeId === '') return;
    setMicrodepositLoading(true);
    let { amount1, amount2 } = microdepositForm;
    if (amount1.length === 1) amount1 += "0";
    if (amount2.length === 1) amount2 += "0";

    try {
      const graphQLClient = new GraphQLClient(GRAPHQL_API_URL + "/graphql", {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });
      const data = await graphQLClient.request(VERIFY_MICRO_DEPOSITS, {
        input: buildVerifyMicroDepositsReq(
            parseFloat('0.' + amount1),
            parseFloat('0.' + amount2),
            bankPendingPaymentNodeId
        ),
      });
      if (data.verifyMicroDeposits.succeeded) {
        handleCloseVerifyModal();
      } else {
        setMicrodepositError(
            "Invalid values. Please try again or contact our support team for assistance."
        );
      }
    } catch (error) {
      setMicrodepositError(
          "Invalid values. Please try again or contact our support team for assistance."
      );
    } finally {
      setMicrodepositLoading(false);
    }
  };

  const getHomeQuery = async () => {
    try {
      const graphQLClient = new GraphQLClient(GRAPHQL_API_URL + "/graphql", {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      const homeData = await graphQLClient.request(HOME_QUERY);
      setTotalBalance(homeData.valuation.total.amount);
      setAvailableBalance(homeData.valuation.availableForWithdrawal.amount);
      setUserId(homeData.me.id);
      setPaymentNodes(homeData.paymentNodes);
      const bankReadyForWithdrawal = homeData.paymentNodes.find(node => node.readyForWithdrawal && node.partner === 'CHECKBOOK');
      setIsBankReadyForWithdrawal(bankReadyForWithdrawal !== undefined)
      setBankMaskReadyForWithdrawal(bankReadyForWithdrawal ? ("•••••" + bankReadyForWithdrawal.mask) : '')
      const bankPending = homeData.paymentNodes.find(node => node.manualVerificationRequired && !node.readyForWithdrawal && node.partner === 'CHECKBOOK');
      setIsBankPending(bankPending !== undefined)
      setBankMaskPending(bankPending ? ("•••••" + bankPending.mask) : '')
      setBankPendingPaymentNodeId(bankPending ? bankPending.id : '');
      setActiveNode(bankReadyForWithdrawal);
      setKycStatus(homeData.me.kycStatus);

      if (!homeData) {
        setHomeDataError(
            "Oops, something went wrong. Please reach out to our support team to manually check the status of your withdrawal."
        );
      }
      setHomeLoading(false);
    } catch (error) {
      setHomeDataError(
          "Oops, something went wrong. Please reach out to our support team to manually check the status of your withdrawal."
      );
    }
  };

  const getTransactions = async () => {
    try {
      const graphQLClient = new GraphQLClient(GRAPHQL_API_URL + "/graphql", {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      const transactionData = await graphQLClient.request(TRANSACTIONS_QUERY);
      setJsonObjectArray(transactionData.transactionStatus.transactions);
      if (
          transactionData &&
          !transactionData.transactionStatus.transactions.length
      ) {
        setHomeDataError("No pending withdrawals");
      } else {
        setHomeDataError("");
      }

      if (!transactionData) {
        setHomeDataError(
            "Oops, something went wrong. Please reach out to our support team to manually check the status of your withdrawal."
        );
      }
      setHomeLoading(false);
    } catch (error) {
      setHomeDataError(
          "Oops, something went wrong. Please reach out to our support team to manually check the status of your withdrawal."
      );
    }
  };

  const generateTaxCSV = async (year) => {
    setCsvLoading(true);
    const csvError =
        "Oops, something went wrong. Please reach out to our support team.";
    try {
      const graphQLClient = new GraphQLClient(GRAPHQL_API_URL + "/graphql", {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      const data = await graphQLClient.request(GENERATE_TAX_CSV, {
        input: buildTaxCSVReq(year),
      });

      if (!data.generateTaxCSV.succeeded) {
        setCsvError(csvError);
      } else {
        const csvString = csv.stringify(data.generateTaxCSV.csv);
        csv.saveFile(`donut_tax_summary_${year}.csv`, csvString);
      }
    } catch (error) {
      setCsvError(csvError);
    } finally {
      setCsvLoading(false);
    }
  };

  const sellAsset = () => {
    if (!isBankReadyForWithdrawal || !availableBalance || availableBalance === 0) {
      return;
    }
    setIsSellSent(false);
    setSellAssetError("");
    setShowSellAssetModal(true);
  };

  const handleSellAssetConfirmation = async () => {
    if(!confirmedWithdrawal) {
      setSellAssetError("Please confirm the terms.");
      return;
    }

    setSellAssetLoading(true);
    // setShowSellAssetModal(false);
    const sellAssetError =
        "Oops, something went wrong. Please reach out to our support team.";
    try {
      const graphQLClient = new GraphQLClient(GRAPHQL_API_URL + "/graphql", {
        headers: {
          authorization: `Bearer ${authToken}`,
        },
      });

      const data = await graphQLClient.request(SELL_ASSETS_MUTATION, {
        input: buildSellAssetReq(activeNode.id, availableBalance),
      });

      if (!data.sellAssets.succeeded) {
        setSellAssetError(sellAssetError);
      } else {
        setIsSellSent(true);
        // fast poll
        pollRef.current = setInterval(() => {
          getHomeQuery();
          getTransactions();
        }, 1000);
        // after 10 seconds clear poll
        setTimeout(() => {
          clearInterval(pollRef.current);
        }, 10000);
      }
    } catch (error) {
      setSellAssetError(sellAssetError);
    } finally {
      setSellAssetLoading(false);
    }
  };

  useEffect(() => {
    getHomeQuery();
    getTransactions();
    // eslint-disable-next-line
  }, []);

  return (
      <>
        <div style={styles.container}>
          <div style={styles.withdrawalsContainer}>
            <h1 style={width > 350 ? styles.semiBold : styles.semiBoldSmall}>
              Account dashboard
            </h1>
            <div
                style={{
                  textAlign: "center",
                  marginTop: 10,
                  color: "#62605D",
                  backgroundColor: "#ffebe0",
                  padding: "20px",
                  borderRadius: "150px",
                }}
            >
              Your funds will be available for withdrawal once the Plan is effective. To ensure a smooth distribution process, please confirm your bank details.
              <br />
            </div>
            <StatusSection
                availableBalance={availableBalance}
                totalBalance={totalBalance}
                homeDataError={homeDataError}
                homeLoading={homeLoading}
                paymentNodes={paymentNodes}
                jsonObjectArray={jsonObjectArray}
                generateTaxCSV={generateTaxCSV}
                activeNode={activeNode}
                setActiveNode={setActiveNode}
                csvLoading={csvLoading}
                csvError={csvError}
                sellAssetLoading={sellAssetLoading}
                sellAsset={sellAsset}
                sellAssetError={sellAssetError}
                handleOpenVerifyModal={handleOpenVerifyModal}
                handleOpenConnectModal={handleOpenConnectModal}
                isBankReadyForWithdrawal={isBankReadyForWithdrawal}
                isBankPending={isBankPending}
                bankReadyForWithdrawalMask={bankMaskReadyForWithdrawal}
                bankPendingMask={bankMaskPending}
                showVerifyIdentityButton={kycStatus === 'UNSUBMITTED'  && availableBalance === 0}
                setShowVerifyIdentityModal={() => {
                  setShowVerifyIdentityModal(true)
                  const veriff = Veriff({
                    host: 'https://stationapi.veriff.com',
                    apiKey: 'a0975e6b-4384-4d3c-9d58-44d8f095fbdf',
                    parentId: 'veriff-root',
                    onSession: function(err, response) {
                      window.location.replace(response.verification.url);
                    },
                    callback: 'https://app.donut.io',
                  });
                  setTimeout(() => {
                    const style = document.createElement('style');
                    style.innerHTML = `
                      #veriff-submit-btn {
                        cursor: pointer;
                        background-color: rgb(31, 30, 29);
                        color: white;
                        border-radius: 16px;
                        padding: 10px;
                      }
                    `;
                    document.head.appendChild(style);
                    
                    veriff.setParams({
                      vendorData: userId,
                    });

                    veriff.mount({
                      formLabel: {
                        givenName: 'First name',
                        lastName: 'Last name',
                      }

                    });
                  }, 1000);
                }
             }
            />
            <br />
            <h3 style={{ fontSize: 24, fontWeight: 600, marginBottom: 10, marginTop: 10 }}>
              Help Center
            </h3>
            <div className="help-center-container">
              <div className="help-center-button" onClick={() => window.open("https://help.donut.app/en/", "_blank").focus()}>
                FAQs
              </div>
              <div className="help-center-button" onClick={() => window.open("mailto:support@donut.app", "_blank").focus()}>
                Support
              </div>
            </div>
          </div>
        </div>
        <br />
        <div style={{ textAlign: "center", marginBottom: 100, color: "#62605D" }}>
          Have a question? Reach out to <a style={{color: "#62605D"}} href='mailto:support@donut.app'><u>support@donut.app</u></a>, we're always available to help.
        </div>
        <BankConnectModal
            showConnectModal={showConnectModal}
            handleCloseConnectModal={handleCloseConnectModal}
            connectBankForm={connectBankForm}
            handleUpdateConnectBankForm={handleUpdateConnectBankForm}
            connectBankLoading={connectBankLoading}
            connectBankError={connectBankError}
            setConnectBankMessage={setConnectBankMessage}
            setConnectBankError={setConnectBankError}
            connectBankMessage={connectBankMessage}
            handleConnectSubmit={handleConnectSubmit}
            paymentNodes={paymentNodes}
            setConnectBankForm={setConnectBankForm}
        />
        <ReactModal
            isOpen={showVerifyModal}
            style={modalStyles}
            contentLabel="Verify payment node modal"
        >
          <ButtonClose onClick={handleCloseVerifyModal} />
          <form style={styles.form}>
            <p style={styles.formTitle}>Complete Verification</p>
            <p style={styles.formDescription}>
              Enter the amounts of each microdeposit as they appear on your bank statement.
            </p>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <label style={styles.microLabels}>
                <span style={{fontSize: 20}}>$</span>0.
                <input
                    style={styles.microInput}
                    type="number"
                    name="amount1"
                    placeholder="00"
                    value={microdepositForm.amount1}
                    onChange={(e) => handleUpdateMicrodepositsForm("amount1", e.target.value)}
                />
              </label>
              <label style={styles.microLabels}>
                <span style={{fontSize: 20}}>$</span>0.
                <input
                    style={styles.microInput}
                    name="amount2"
                    placeholder="00"
                    type="number"
                    value={microdepositForm.amount2}
                    onChange={(e) => handleUpdateMicrodepositsForm("amount2", e.target.value)}
                />
              </label>
            </div>
            <p style={{...styles.formDescription, paddingTop: 40}}>
              Please note you can have up to three entries. If there’s an issue after two entries, please contact our support team.
            </p>
            {!microdepositLoading && microdepositError !== "" && (
                <div style={{paddingTop: 10, color: "red"}}>
                  {microdepositError}
                </div>
            )}
          </form>
          <Button style={{maxWidth: "100%", margin: 0, marginTop: 30}} isLoading={microdepositLoading} onClick={handleMicrodepositsSubmit}>
            Confirm
          </Button>
        </ReactModal>

        <ReactModal
            isOpen={showSellAssetModal}
            style={modalStyles}
            contentLabel="Sell Asset Confirmation"
        >
          <ButtonClose onClick={() => setShowSellAssetModal(false)} />
          <form style={styles.form}>
            {!isSellSent && (
                    <>
                    <p style={styles.formTitle}>Confirm your withdrawal</p>
                <p style={styles.formDescription}>
                  Bank account: {bankMaskReadyForWithdrawal}
                </p>
                <p style={styles.formDescription}>
                  Amount: {formattedAmount(availableBalance)}
                </p>
                <p style={styles.formDescription}>
                  Please allow 3-5 business days for withdrawals to process.
                </p>
                <div style={styles.checkboxContainer}>
                  <label htmlFor="confirmCheckbox" style={styles.checkboxLabel}  onChange={(e) => {
                    setConfirmedWithdrawal(e.target.checked);
                  }}>
                    <input type="checkbox" id="confirmCheckbox" style={styles.checkbox}/>
                    I confirm that the details are correct and I understand that after this, my account will be closed and I will no longer have access to it.
                  </label>
                </div>
                {!sellAssetLoading && sellAssetError !== "" && (
                    <div style={{paddingTop: 10, color: "red"}}>
                      {sellAssetError}
                    </div>
                )}
                </>
            )}
            {isSellSent && (
                <>
                  <p style={styles.formTitle}>Congratulations!</p>
                  <p style={styles.formDescription}>
                    Your transaction has been initiated.
                  </p>
                  <p style={styles.formDescription}>
                    You can close this window and track your withdrawal in your dashboard.
                  </p>
                  <p style={styles.formDescription}>
                    Please allow up to 5 business days for your withdrawal to process.
                  </p>
                </>
            )}
          </form>
          {!isSellSent && (
              <Button style={{maxWidth: "100%", margin: 0, marginTop: 30}} isLoading={sellAssetLoading} onClick={handleSellAssetConfirmation}>
              Confirm
            </Button>
          )}
        </ReactModal>

        <ReactModal
            isOpen={showVerifyIdentityModal}
            style={modalStyles}
            contentLabel="Verify identity"
        >
          <ButtonClose onClick={() => setShowVerifyIdentityModal(false)} />
          <form style={styles.form}>
            <p style={styles.formTitle}>Verify Your Identity</p>
            <p style={styles.formDescription}>
              The verification process typically takes 3-5 business days to complete.
            </p>
            <p style={styles.formDescription}>
              If you’ve already submitted documents, please wait for approval rather than resubmitting your information.
            </p>
            <div id="veriff-root"></div>
          </form>
        </ReactModal>

      </>
  );
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const formattedAmount = (amount) => {
  if (!amount) {
    return formatter.format(0);
  }
  const partOne = amount.toString().slice(0, -2);
  const partTwo = amount.toString().slice(-2);
  const combined = partOne + "." + partTwo;
  return formatter.format(combined);
};

const styles = {
  container: {
    maxWidth: 1000,
    width: "100%",
    margin: 0,
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    alignItems: "center",
  },
  checkboxLabel: {
    fontSize: 14,
    color: "#444444",
  },
  checkbox: {
    marginRight: 10,
  },
  instructionsContainer: {
    alignItems: "center",
    marginTop: 0,
    marginBottom: 20,
    maxWidth: 400,
    width: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  withdrawalsContainer: {
    alignItems: "center",
    marginTop: 0,
    marginBottom: 20,
    maxWidth: 800,
    width: "100%",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  logo: {
    width: "auto",
    height: 40,
    marginTop: 20,
    marginLeft: 20,
    flex: 1,
  },
  semiBold: {
    fontSize: 24,
    fontWeight: 700,
    textAlign: "center",
  },
  semiBoldSmall: {
    fontSize: 20,
    fontWeight: 700,
    textAlign: "center",
  },
  form: {
    paddingTop: 20,
  },
  formTitle: {
    marginTop: -10,
    textAlign: "center",
    fontWeight: 500,
    fontSize: 16,
    color: "#242424",
  },
  formDescription: {
    textAlign: "center",
    fontSize: 14,
    color: "#444444",
    padding: "0 30px",
  },
  labelStyle: { textAlign: "center", fontSize: 13, color: "#242424" },
  formInput: {
    width: "calc(100% - 10px)",
    marginTop: 5,
    marginBottom: 5,
    border: "1px solid #e1e1e1",
    padding: "10px 5px",
    borderRadius: 6,
    fontSize: 16,
  },
  microLabels: {
    fontSize: 34,
    borderBottom: "2px solid #e1e1e1",
    margin: "0 10px",
    marginTop: 20,
  },
  microInput: {
    fontSize: 34,
    backgroundColor: "transparent",
    border: "none",
    width: 50,
  },
  modalContent: {
    textAlign: "center",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    paddingTop:20
  },
};

const modalStyles = {
  content: {
    borderRadius: 16,
    top: 0,
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, 0)",
    width: "90%",
    maxWidth: "400px",
    position: "relative",
    marginTop: "30px",
    overflow: "visible",
  },
  overlay: {
    backgroundColor: "rgb(133 133 133 / 42%)",
    overflow: "auto",
  },
};

export default HomeScreen;
