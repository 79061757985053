function SwitchButton(props) {
  const { style, values, display, current, onChange } = props;
  return (
    <div
      className="switch-button-container"
      style={{ ...styles.root, ...style }}
    >
      <div
        className={
          current === 0 ? "switch-button switch-button-active" : "switch-button"
        }
        onClick={() => onChange(values[0])}
      >
        {display[0]}
      </div>
      <div
        className={
          current === 1 ? "switch-button switch-button-active" : "switch-button"
        }
        onClick={() => onChange(values[1])}
      >
        {display[1]}
      </div>
    </div>
  );
}

const styles = {
  root: {},
};

export default SwitchButton;
