import loader from "../assets/loader.gif";
import "react-phone-number-input/style.css";

import PhoneInput from "react-phone-number-input";

function PhoneNumberStep({
  description,
  setPhoneNumber,
  setCountryCode,
  requestOTPError,
  validNumber,
  requestOTP,
  loading,
}) {
  return (
    <>
      <div style={{ marginTop: 40, color: "#62605D", textAlign: "center" }}>
        {description}
      </div>
      <div style={{ width: "100%", alignItems: "center", marginTop: 30 }}>
        <PhoneInput
          autocomplete="off"
          defaultCountry={"US"}
          international
          onChange={(value) => setPhoneNumber(value)}
          onCountryChange={(value) => setCountryCode(value?.toUpperCase())}
        />
        {requestOTPError !== "" && (
          <div style={{ color: "red", textAlign: "center" }}>
            {requestOTPError}
          </div>
        )}
        <div
          onClick={() => validNumber && requestOTP()}
          style={
            validNumber ? styles.submitButton : styles.submitButtonDisabled
          }
        >
          {!loading && <>Continue</>}
          {loading && <img alt="loading" src={loader} style={styles.loader} />}
        </div>
      </div>
    </>
  );
}

const styles = {
  submitButton: {
    cursor: "pointer",
    marginTop: 60,
    bottom: 20,
    color: "white",
    backgroundColor: "#ff7c7c",
    borderRadius: 5,
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submitButtonDisabled: {
    marginTop: 60,
    color: "white",
    backgroundColor: "#ffbdbd",
    borderRadius: 5,
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    height: 20,
    width: 20,
  },
  input: {
    marginTop: 5,
    marginBottom: 5,
    border: "1px solid #e1e1e1",
    padding: "10px 5px",
    borderRadius: 6,
    fontSize: 16,
  },
};

export default PhoneNumberStep;
