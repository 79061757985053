import close from "../assets/close.svg";

function ButtonClose(props) {
  const { style, onClick } = props;
  return (
    <div
      className="button"
      onClick={onClick}
      style={{ ...styles.root, ...style }}
    >
      <img alt="close" src={close} style={styles.close} />
    </div>
  );
}

const styles = {
  root: {
    cursor: "pointer",
    position: "absolute",
    top: 4,
    left: 4,
  },
  close: {
    height: 30,
    width: 30,
  },
};

export default ButtonClose;
