import { useCallback, useEffect, useRef, useState } from "react";

/*
Counts down the given amount of seconds, starting automatically.
*/
const useCountdown = (seconds) => {
  const [remaining, setRemaining] = useState(seconds);
  const [running, setRunning] = useState(true);

  const remainingRef = useRef(seconds);

  const start = useCallback(() => {
    setRemaining(seconds);
    setRunning(true);
  }, [seconds]);

  useEffect(() => {
    remainingRef.current = remaining;
  }, [remaining]);

  useEffect(() => {
    const tick = () => {
      if (remainingRef.current <= 0) {
        setRunning(false);
      } else {
        setRemaining((prev) => prev - 1);
      }
    };
    let id;
    if (running) {
      id = setInterval(tick, 1000);
    }
    return () => clearInterval(id);
  }, [running]);

  return {
    secondsRemaining: remaining,
    start,
  };
};

export default useCountdown;
