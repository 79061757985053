import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import SwitchButton from './SwitchButton'; // Assuming SwitchButton is a custom component
import Button from './Button'; // Assuming Button is a custom component
import ButtonClose from './ButtonClose'; // Assuming ButtonClose is a custom component

const ConnectBankModal = ({ setConnectBankForm, onClose, showConnectModal, connectBankMessage, setConnectBankError, setConnectBankMessage, handleCloseConnectModal, paymentNodes, handleUpdateConnectBankForm, connectBankForm, connectBankLoading, connectBankError, handleConnectSubmit }) => {
    const [confirmDetails, setConfirmDetails] = useState(false);
    const [confirmationStep, setConfirmationStep] = useState(false);
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
    const [confirmRoutingNumber, setConfirmRoutingNumber] = useState('');
    const [backendMessage, setBackendMessage] = useState('');
    const [errors, setErrors] = useState({});

    const closeModal = () => {
        setConfirmDetails(false);
        setConfirmationStep(false);
        setConfirmAccountNumber('');
        setConfirmRoutingNumber('');
        setErrors({});
        setConnectBankMessage('');
        setConnectBankError('');
        setBackendMessage('');
        setConnectBankForm({
            accountNumber: "",
            routingNumber: "",
            lastFourDigitsSSN: "",
            confirmationDate: "",
            accountType: "CHECKING",
        });
        handleCloseConnectModal();
    };

    const validateForm = () => {
        const newErrors = {};
        if (connectBankForm.accountNumber.length < 6) {
            newErrors.accountNumber = 'Account number must be more than 6 digits long.';
        }
        if (connectBankForm.routingNumber.length !== 9) {
            newErrors.routingNumber = 'Routing number must be 9 digits.';
        }
        if (connectBankForm.lastFourDigitsSSN.length !== 4) {
            newErrors.lastFourDigitsSSN = 'SSN must be the last 4 digits.';
        }
        if (!confirmDetails) {
            newErrors.confirmDetails = 'You must confirm the details.';
        }
        return newErrors;
    };


    const handleInitialSubmit = () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            setErrors({});
            setConfirmationStep(true);
        }
    };


    const handleFinalSubmit = () => {
        if (connectBankForm.accountNumber === confirmAccountNumber && connectBankForm.routingNumber === confirmRoutingNumber) {
            handleConnectSubmit();
        } else {
            setBackendMessage('Account details do not match.');
        }
    };

    const handleBackendResponse = (response) => {
        if (response.success) {
            setBackendMessage('Your bank details have been successfully connected.');
        } else {
            setBackendMessage('Failed to connect bank details. Please try again.');
        }
    };

    useEffect(() => {
        if (connectBankError || connectBankMessage) {
            setBackendMessage(connectBankMessage || connectBankError);
        }
    }, [connectBankError, connectBankMessage]);

    return (
        <ReactModal
            isOpen={showConnectModal}
            style={modalStyles}
            contentLabel="Connect payment node modal"
        >
            <ButtonClose onClick={closeModal} />
            {!confirmationStep ? (
                <form style={styles.form}>
                    <p style={styles.formTitle}>Confirm Bank Account</p>
                    <p style={styles.formDescription}>
                        Please confirm the connected bank account you will withdraw to by entering its details
                        below.<br/><br/>
                        Previously connected bank accounts: {paymentNodes?.map((p) => ("•••••" + p.mask))?.join(", ")}
                        <br/><br/>*Confirming a previously
                        connected account will speed up the distribution process.
                    </p>
                    <br/>
                    <div>
                        <label style={styles.labelStyle}>Account type </label>
                        <br/>
                        <SwitchButton
                            onChange={(value) =>
                                handleUpdateConnectBankForm("accountType", value)
                            }
                            values={["CHECKING", "SAVINGS"]}
                            display={["Checking", "Savings"]}
                            current={["CHECKING", "SAVINGS"].indexOf(
                                connectBankForm.accountType
                            )}
                        />
                    </div>
                    <div>
                        <label>
                            <input
                                onChange={(e) => handleUpdateConnectBankForm("accountNumber", e.target.value)}
                                type="number"
                                value={connectBankForm.accountNumber}
                                placeholder="Account Number"
                                name="accountNumber"
                                style={styles.formInput}
                            />
                            {errors.accountNumber &&
                                <span style={{paddingTop: 2, color: "red"}}>{errors.accountNumber}</span>}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                onChange={(e) => handleUpdateConnectBankForm("routingNumber", e.target.value)}
                                type="number"
                                placeholder="9-digit Routing Number"
                                value={connectBankForm.routingNumber}
                                name="routingNumber"
                                style={styles.formInput}
                            />
                            {errors.routingNumber &&
                                <span style={{paddingTop: 2, color: "red"}}>{errors.routingNumber}</span>}
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                onChange={(e) => handleUpdateConnectBankForm("lastFourDigitsSSN", e.target.value)}
                                type="number"
                                placeholder="Last 4 digits of SSN"
                                value={connectBankForm.lastFourDigitsSSN}
                                name="lastFourDigitsSSN"
                                style={styles.formInput}
                            />
                            {errors.lastFourDigitsSSN &&
                                <span style={{paddingTop: 2, color: "red"}}>{errors.lastFourDigitsSSN}</span>}
                        </label>
                    </div>
                    {/*<p style={{...styles.formDescription, color: "rgb(100, 100, 100)"}}>*/}
                    {/*    *Confirming a previously*/}
                    {/*    connected account will speed up the distribution process.*/}
                    {/*</p>*/}
                    <div style={styles.checkboxContainer}>
                    <label style={styles.checkboxLabel}>
                                <input
                                    type="checkbox"
                                    checked={confirmDetails}
                                    onChange={(e) => {
                                        setConfirmDetails(e.target.checked);
                                        handleUpdateConnectBankForm("confirmationDate", new Date().toISOString());
                                    }}
                                    style={styles.checkbox}
                                />
                                I confirm I have access to this bank account and that I have entered the details
                                correctly.
                                I acknowledge that any mistakes are my responsibility.
                                {errors.confirmDetails &&
                                    <span style={{paddingTop: 2, color: "red"}}><br/>{errors.confirmDetails}</span>}
                            </label>
                        </div>
                        {backendMessage && (
                            <div style={{paddingTop: 5, paddingBottom: 5, color: "red"}}>
                                {backendMessage}
                            </div>
                        )}
                        <Button
                            style={{maxWidth: "100%", margin: 0, marginTop: 30}}
                            isLoading={connectBankLoading}
                            onClick={handleInitialSubmit}
                        >
                            Confirm
                        </Button>
                </form>
                ) : (
                <form style={styles.form}>
                    {(connectBankMessage !== "" || connectBankError !== "") && (
                        <>
                            <div style={{paddingTop: 10, textAlign: 'center'}}>
                                <div dangerouslySetInnerHTML={{__html: connectBankMessage}}/>
                            </div>
                            <div style={{paddingTop: 10, color: "red"}}>
                                {connectBankError}
                            </div>
                        </>
                    )}


                    {connectBankMessage === "" && connectBankError === "" && (
                        <>
                            <p style={styles.formTitle}>Confirm Bank Account</p>
                            <div>
                                <label>
                                    <input
                                        onChange={(e) =>
                                            setConfirmAccountNumber(e.target.value)
                                        }
                                        type="number"
                                        value={confirmAccountNumber}
                                        placeholder="Confirm Account Number"
                                        name="confirmAccountNumber"
                                        style={styles.formInput}
                                    />
                                </label>
                            </div>
                            <div style={{marginBottom: 15}}>
                                <label>
                                    <input
                                        onChange={(e) =>
                                            setConfirmRoutingNumber(e.target.value)
                                        }
                                        type="number"
                                        placeholder="Confirm 9-digit Routing Number"
                                        value={confirmRoutingNumber}
                                        name="confirmRoutingNumber"
                                        style={ styles.formInput }
                                    />
                                </label>
                            </div>
                            {backendMessage && (
                                <div style={{paddingBottom: 5, color: "red"}}>
                                    {backendMessage}
                                </div>
                            )}
                            <Button
                                style={{maxWidth: "100%", margin: 0, marginTop: 30}}
                                isLoading={connectBankLoading}
                                onClick={handleFinalSubmit}
                            >
                                Confirm
                            </Button>
                        </>
                    )}
                </form>
            )}
        </ReactModal>
    );
};


const styles = {
    container: {
        maxWidth: 1000,
        width: "100%",
        margin: 0,
        paddingLeft: 20,
        paddingRight: 20,
        display: "flex",
        alignItems: "center",
    },
    instructionsContainer: {
        alignItems: "center",
        marginTop: 0,
        marginBottom: 20,
        maxWidth: 400,
        width: "100%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    withdrawalsContainer: {
        alignItems: "center",
        marginTop: 0,
        marginBottom: 20,
        maxWidth: 800,
        width: "100%",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
    },
    logo: {
        width: "auto",
        height: 40,
        marginTop: 20,
        marginLeft: 20,
        flex: 1,
    },
    semiBold: {
        fontSize: 24,
        fontWeight: 700,
        textAlign: "center",
    },
    semiBoldSmall: {
        fontSize: 20,
        fontWeight: 700,
        textAlign: "center",
    },
    form: {
        paddingTop: 20,
    },
    formTitle: {
        marginTop: -10,
        textAlign: "center",
        fontWeight: 500,
        fontSize: 16,
        color: "#242424",
    },
    formDescription: {
        textAlign: "center",
        fontSize: 14,
        color: "#444444",
        padding: "0 30px",
    },
    labelStyle: {textAlign: "center", fontSize: 13, color: "#242424"},
    formInput: {
        width: "calc(100% - 10px)",
        marginTop: 5,
        marginBottom: 5,
        border: "1px solid #e1e1e1",
        padding: "10px 5px",
        borderRadius: 6,
        fontSize: 16,
    },
    microLabels: {
        fontSize: 34,
        borderBottom: "2px solid #e1e1e1",
        margin: "0 10px",
        marginTop: 20,
    },
    microInput: {
        fontSize: 34,
        backgroundColor: "transparent",
        border: "none",
        width: 50,
    },
    checkboxContainer: {
        marginTop: 10,
        marginBottom: 10,
        padding: '10px 0',
    },
    checkboxLabel: {
        fontSize: 14,
        color: "#444444",
    },
    checkbox: {
        marginRight: 10,
    },
};

const modalStyles = {
    content: {
        borderRadius: 16,
        top: 0,
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, 0)",
        width: "90%", // Adjust this width as needed
        maxWidth: "400px", // Set a max-width if needed
        position: "relative",
        marginTop: "30px",
        overflow: "visible", // Allow overflow to be visible
    },
    overlay: {
        backgroundColor: "rgb(133 133 133 / 42%)",
        overflow: "auto", // Ensure the overlay allows scrolling
    },
};

export default ConnectBankModal;
