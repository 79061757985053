const DELIMINATOR = ",";

export const saveFile = (filename, data) => {
  const blob = new Blob([data], { type: "text/csv" });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
};

export const stringify = (data) => {
  const jsonData = JSON.parse(data);
  if (!data.length) {
    return "";
  }

  let results = [];
  for (let entry of jsonData) {
    let row = [];

    let cells = Object.values(entry);
    for (let cell of cells) {
      if (cell.includes(DELIMINATOR)) {
        row.push(`"${cell}"`);
      } else {
        row.push(cell);
      }
    }

    results.push(row.join(DELIMINATOR) + "\n");
  }

  return results.join("");
};
