import loader from "../assets/loader.gif";

function ButtonSecondary(props) {
  const { style, onClick, children, isLoading } = props;
  return (
    <div
      className="button"
      onClick={onClick}
      style={{ ...styles.root, ...style }}
    >
      {isLoading ? (
        <img alt="loading" src={loader} style={styles.loader} />
      ) : (
        children
      )}
    </div>
  );
}

const styles = {
  root: {
    cursor: "pointer",
    border: "1px solid #e1e1e1",
    borderRadius: 16,
    padding: 10,
    marginTop: 5,
    display: "flex",
    marginRight: 5,
    justifyContent: "center",
    alignItems: "center",
    width: 110,
    minHeight: 25,
  },
  loader: {
    height: 20,
    width: 20,
  },
};

export default ButtonSecondary;
