import loader from "../assets/loader.gif";

import useWindowDimensions from "../hooks/useWindowDimensions";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { PinInput } from "react-input-pin-code";

function PinCodeStep({
  pinCode,
  description,
  setPinCode,
  loginError,
  paired,
  decreaseStep,
  validPinCode,
  submitPin,
  loading,
  forgotPin,
}) {
  const { width } = useWindowDimensions();

  return (
    <>
      <div style={{ marginTop: 40, color: "#62605D", textAlign: "center" }}>
        {description}
      </div>
      <div
        style={{
          width: "100%",
          alignItems: "center",
          marginTop: 30,
          height: 50,
        }}
      >
        <PinInput
          size={width > 300 ? "md" : "sm"}
          placeholder="•"
          type="number"
          borderColor="#DCD9D5"
          focusBorderColor="#AEABA6"
          validBorderColor="#FFC693"
          containerStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          values={pinCode}
          onChange={(value, index, values) => setPinCode(values)}
        />
        {forgotPin && (
          <div onClick={forgotPin} style={styles.forgotButton}>
            <div style={{ cursor: "pointer", color: "#ff7c7c" }}>
              Forgot PIN?
            </div>
          </div>
        )}
        {loginError !== "" && (
          <div style={{ color: "red", textAlign: "center" }}>{loginError}</div>
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          {!paired && (
            <div onClick={() => decreaseStep()} style={styles.backButton}>
              <KeyboardArrowLeftIcon />
            </div>
          )}
          <div
            onClick={() => validPinCode && submitPin()}
            style={
              validPinCode ? styles.submitButton : styles.submitButtonDisabled
            }
          >
            {!loading && <>Continue</>}
            {loading && (
              <img alt="loading" src={loader} style={styles.loader} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  submitButton: {
    cursor: "pointer",
    marginTop: 60,
    bottom: 20,
    color: "white",
    backgroundColor: "#ff7c7c",
    borderRadius: 5,
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backButton: {
    cursor: "pointer",
    marginTop: 60,
    bottom: 20,
    width: 60,
    marginRight: 10,
    color: "white",
    backgroundColor: "#ff7c7c",
    borderRadius: 5,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submitButtonDisabled: {
    marginTop: 60,
    color: "white",
    backgroundColor: "#ffbdbd",
    borderRadius: 5,
    width: "100%",
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    height: 20,
    width: 20,
  },
  forgotButton: {
    pointer: "cursor",
    marginTop: 10,
    color: "#312F2E",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default PinCodeStep;
