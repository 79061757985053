import { useEffect, useState } from "react";
import loader from "../assets/loader.gif";
import carrot from "../assets/carrot.svg";
import bank from "../assets/bank.svg";
import plus from "../assets/plus.svg";
import Button from "./Button";
import useWindowDimensions from "../hooks/useWindowDimensions";

function PaymentNodeSelect(props) {
  const {
    style,
    paymentNodes,
    activeNode,
    setActiveNode,
    onConnectBank,
    onVerifyNode,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (paymentNodes?.length && !activeNode) {
      setActiveNode(paymentNodes.find((node) => node.isPreferred));
    }
  }, [activeNode, paymentNodes, setActiveNode]);

  const handleSelectNode = (node) => {
    if (node.status !== "SYNCED") return;
    setActiveNode(node);
    setIsOpen(false);
  };

  const renderNoNodesMessage = () => (
      <div
          // onClick={handleConnectBank}
          // style={{
          //   ...styles.container,
          //   justifyContent: 'center', // Center the content
          //   marginTop: 0,
          //   paddingTop: 10,
          // }}
      >
        {/*<img alt="add" src={plus} style={styles.logo} />*/}
        {/*<div style={styles.connectText}>Add Bank Account</div>*/}
      </div>
  );

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleConnectBank = () => {
    setIsOpen(false);
    onConnectBank();
  };

  const handleVerifyNode = (node) => {
    setIsOpen(false);
    onVerifyNode(node);
  };

  let selectorWidth = width > 400 ? 350 : "calc(100% - 20px)";
  let dropDownWidth = width > 400 ? 350 : "calc(100% - 60px)";
  let textWidth = width > 400 ? 270 : width * 0.6;
  return (
    <>
      <div
        // onClick={paymentNodes?.length === 0 ? () => {} : toggleOpen}
        style={{ ...styles.root, ...style, ...{ width: selectorWidth } }}
      >
        {activeNode === null ? (
            paymentNodes?.length === 0 ? (
                // Call the function to render the message for adding a bank account
                renderNoNodesMessage()
            ) : (
                <div style={styles.loaderContainer}>
                  <img alt="loading" src={loader} style={styles.loader} />
                </div>
            )
        ) : (
          <div style={styles.container}>
            <div style={styles.innerContainer}>
              <img
                alt="bank"
                src={
                  activeNode.institution.logo
                    ? "data:image/png;base64," + activeNode.institution.logo
                    : bank
                }
                style={styles.logo}
              />

              <div style={styles.textContainer}>
                <p style={styles.name}>
                  {activeNode.name + " • " + activeNode.institution.name}
                </p>
                <p style={styles.mask}>{"•••••" + activeNode.mask}</p>
              </div>
            </div>

            {/*<img*/}
            {/*  className={isOpen ? "carrot carrot-active" : "carrot"}*/}
            {/*  alt="carrot"*/}
            {/*  src={carrot}*/}
            {/*  style={styles.carrot}*/}
            {/*/>*/}
          </div>
        )}
      </div>
      <div
        className={isOpen ? "dropdown dropdown-active" : "dropdown"}
        style={{ ...styles.dropDown, ...{ width: dropDownWidth } }}
      >
        {!paymentNodes
          ? null
          : paymentNodes
              .sort((node) => (node.status === "SYNCED" ? -1 : 1))
              .map((node) => {
                if (
                  node.status !== "SYNCED" &&
                  node.status !== "PENDING_MANUAL_VERIFICATION"
                )
                  return null;
                return (
                  <div
                    key={node.id}
                    style={
                      node.status === "PENDING_MANUAL_VERIFICATION"
                        ? styles.pendingNode
                        : null
                    }
                  >
                    <div
                      onClick={() => handleSelectNode(node)}
                      style={styles.container}
                      className={
                        node.status === "PENDING_MANUAL_VERIFICATION"
                          ? "verification-dropdown-item"
                          : "dropdown-item"
                      }
                    >
                      <div style={styles.innerContainer}>
                        <img
                          alt="bank"
                          src={
                            node.institution.logo
                              ? "data:image/png;base64," + node.institution.logo
                              : bank
                          }
                          style={styles.logo}
                        />
                        <div style={styles.textContainer}>
                          <p
                            style={{
                              ...styles.name,
                              ...{ maxWidth: textWidth },
                            }}
                          >
                            {node.name + " • " + node.institution.name}
                          </p>
                          <p style={styles.mask}>
                            {"•••••" + node.mask}
                            {node.status === "PENDING_MANUAL_VERIFICATION" && (
                              <span style={styles.tag}>Pending</span>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    {node.status === "PENDING_MANUAL_VERIFICATION" && (
                      <Button
                        onClick={() => handleVerifyNode(node)}
                        style={styles.verifyButton}
                      >
                        Complete Verification
                      </Button>
                    )}
                  </div>
                );
              })}
        {/*{paymentNodes?.length < 4 && (*/}
        {/*  // <div*/}
        {/*  //   onClick={handleConnectBank}*/}
        {/*  //   key={"connect-bank"}*/}
        {/*  //   style={{*/}
        {/*  //     ...styles.container,*/}
        {/*  //     ...{*/}
        {/*  //       marginTop: 10,*/}
        {/*  //       borderTop: "1px solid #e1e1e1",*/}
        {/*  //       paddingTop: 10,*/}
        {/*  //     },*/}
        {/*  //   }}*/}
        {/*  //   className="dropdown-item"*/}
        {/*  // >*/}
        {/*  //   <div style={styles.innerContainer}>*/}
        {/*  //     <img alt="add" src={plus} style={styles.logo} />*/}
        {/*  //     <div style={styles.connectText}>Add Bank Account</div>*/}
        {/*  //   </div>*/}
        {/*  // </div>*/}
        {/*)}*/}
      </div>
    </>
  );
}

const styles = {
  root: {
    borderRadius: 16,
    border: "1px solid #e1e1e1",
    padding: "10px",
    paddingTop: 3,
    marginTop: 21,
    marginRight: 20,
    width: 350,
    pointer: "cursor",
    background: "#faf9f7",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  innerContainer: {
    display: "flex",
    alignItems: "center",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    height: 30,
    width: 30,
    marginRight: 5,
    borderRadius: "50%",
  },
  loader: {
    height: 20,
    width: 20,
  },
  carrot: {
    height: 10,
    width: 10,
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
  },
  name: {
    fontSize: 14,
    margin: 8,
    marginBottom: 11,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  mask: {
    lineHeight: 0,
    fontSize: 11,
    color: "#606060",
    margin: 8,
    marginLeft: 7,
    marginTop: -2,
  },
  connectText: {
    marginLeft: 7,
    color: "#212121",
  },
  verificationStatus: {
    lineHeight: 0,
    fontSize: 11,
    color: "#606060",
  },
  dropDown: {
    width: 350,
    border: "1px solid #e1e1e1",
    padding: 10,
    position: "absolute",
    background: "#faf9f7",
    marginTop: 3,
    borderRadius: 16,
    zIndex: 10,
  },
  verifyButton: {
    maxWidth: "unset",
    margin: "10px 15px",
  },
  pendingNode: {
    background: "#FFF",
    padding: 5,
    borderRadius: 16,
    marginTop: 10,
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
  tag: {
    marginLeft: 10,
    background: "#ffeddc",
    color: "#ffa451",
    padding: "3px 8px",
    borderRadius: 16,
    fontWeight: 500,
  },
};

export default PaymentNodeSelect;
