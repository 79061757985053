import { gql } from "graphql-request";

export const GENERATE_TAX_CSV = gql`
  mutation GenerateTaxCSV($input: GenerateTaxCSVInput!) {
    generateTaxCSV(input: $input) {
      csv
      succeeded
    }
  }
`;

export const VERIFY_MICRO_DEPOSITS = gql`
  mutation VerifyMicroDeposits($input: VerifyMicroDepositsInput!) {
    verifyMicroDeposits(input: $input) {
      succeeded
      userErrors {
        code
        message
      }
    }
  }
`;

export const ADD_MANUAL_BANK_ACCOUNT = gql`
  mutation AddManualBankAccount($input: AddManualBankAccountInput!) {
    addManualBankAccount(input: $input) {
      succeeded
      userErrors {
        code
        message
      }
    }
  }
`;

export const SELL_ASSETS_MUTATION = gql`
  mutation SellAssets($input: SellAssetsInput!) {
    sellAssets(input: $input) {
      succeeded
    }
  }
`;

export const buildTaxCSVReq = (year) => {
  return {
    forYear: year,
  };
};

export const buildSellAssetReq = (paymentNodeId, amount) => {
  return {
    amount: {
      amount: amount,
      currencyCode: "USD",
      precision: 2,
    },
    paymentDestinationId: paymentNodeId,
    assetCode: "SVG",
  };
};

export const buildAddManualBankAccountReq = (
  accountType,
  accountNumber,
  routingNumber,
  lastFourDigitsSSN
) => {
  return {
    accountType: accountType,
    accountNumber: accountNumber,
    routingNumber: routingNumber,
    lastFourDigitsSSN: lastFourDigitsSSN,
    nodeType: "ACH_US",
  };
};

export const buildVerifyMicroDepositsReq = (
  microDeposit1,
  microDeposit2,
  paymentNodeId
) => {
  return {
    microDeposits: [microDeposit1, microDeposit2],
    paymentNodeID: paymentNodeId,
  };
};
