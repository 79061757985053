import { gql } from "graphql-request";

export const HOME_QUERY = gql`
  {
    kycSubmissionDate
    me {
       kycStatus
       id
    }
    paymentNodes(filter: { types: [BANK_ACCOUNT], scope: DEBIT }) {
      ... on BankAccount {
        externalId
        id
        mask
        name
        institution {
          id
          logo
          name
        }
        fee
        manualVerificationRequired
        manualVerificationSucceeded
        readyForWithdrawal
        isPreferred
        deletability
        partner
        status
      }
    }
    valuation: valuations(assetCodes: [SVG], targetCurrency: USD) {
      total {
        amount
        currency {
          isoCode
          precision
        }
      }
      availableForWithdrawal {
        amount
        currency {
          isoCode
          precision
        }
      }
    }
  }
`;

export const TRANSACTIONS_QUERY = gql`
  {
    transactionStatus {
      transactions {
        updatedDate
        name
        amount {
          amount
          currency {
            isoCode
            precision
          }
        }
      }
    }
  }
`;
